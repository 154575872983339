.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: var(--primary-teal);
  border: 1px solid var(--primary-teal);
  border-radius: 20px;
  padding: 0 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: var(--primary-navy);
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
}

.button:hover {
  background-color: #28d18a;
  border-color: #28d18a;
}

.button:disabled,
.button[disabled] {
  background-color: rgb(38 255 164 / 20%);
  border-color: rgb(38 255 164 / 20%);
}

.button.outline {
  background: transparent;
  color: var(--primary-teal);
}
