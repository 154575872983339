:root {
  --primary-navy: #103a52;
  --primary-teal: #26ffa4;
  --primary-teal-80: #28d18a;
  --primary-teal-40: rgb(38 255 164 / 40%);
  --primary-teal-20: rgb(38 255 164 / 20%);

  --secondary-blue: #00b2ff;
  --secondary-light-blue: #85fff8;
  --secondary-red: #ff9898;

  --header-failed-first: #ff6262;
  --header-failed-last: #ff9898;

  --gradient-heavy-first: #26ffa4;
  --gradient-heavy-last: #00b2ff;

  --gradient-light-first: #a1ff99;
  --gradient-light-last: #99ceff;

  --gradient-bg-first: #1b303f;
  --gradient-bg-last: #393939;

  --gray: #c8c8c8;
  --gray-80: #232323;
  --white: #ffffff;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700;900&display=swap');

* {
  font-family: 'Noto Sans TC', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  outline: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    180deg,
    var(--gradient-bg-first) 0%,
    var(--gradient-bg-last) 100%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  padding: 0;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

textarea,
input {
  appearance: none;
  border-radius: 0;
}

/* Remove the div wrap image unknown padding */
img {
  display: block;
}

h1 {
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 40px;
  color: var(--primary-navy);
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-navy);
}

p.global {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-navy);
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-navy);
}

p.small {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: var(--primary-navy);
}
