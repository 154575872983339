.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  border-bottom: 1px solid rgba(51, 133, 128, 0.6);
}

.left {
  display: flex;
  align-items: center;
  height: 60px;
}

.navigation {
  display: flex;
  margin-left: 80px;
  height: 60px;
}

.navigation > a {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray);
}

.navigation > a.active {
  color: var(--primary-teal);
}

.right {
  display: flex;
  height: 60px;
  align-items: center;
}

.action {
  display: flex;
  height: 30px;
}
